<template>
  <div><van-empty image="error" description="404：页面丢失" /></div>
</template>

<script>
export default {
  components: {
  },
};
</script>

<style></style>
